import React from 'react';
import {
  Card,
} from 'react-bootstrap';

import DataField from '../data-field/index.ts';

import './style.css';

interface BeansCardProps {
  coffeeName: string,
  coffeeBrand?: string,
  roastDate?: Date,
  imageUrl?: string,
}

type Variant = 'a' | 'b';

const BeansCard = (props: BeansCardProps) => {
  const {
    coffeeName, coffeeBrand, roastDate, imageUrl,
  } = props;

  const formatDate = (date: Date) => date.toLocaleDateString();

  const variant : Variant = 'b';

  // maybe move these settings to "user" settings,
  // so you can optimize your own drinking experience.
  const bestFrom = 3 * 7;
  const bestUntil = 6 * 7;

  const usageAdvice = () => {
    if (!roastDate) {
      return '-';
    }
    const timeDiff = new Date().getTime() - roastDate.getTime();
    const days = Math.round(timeDiff / (1000 * 3600 * 24));

    let usage;
    if (days > bestUntil) {
      let timeUnit;
      if (days > 6 * 7) {
        timeUnit = `${Math.floor(days / 31)} maanden`;
      } else {
        timeUnit = `${days} dagen`;
      }
      usage = `Snel opmaken, deze bonen zijn ${timeUnit} oud`;
    } else if (days >= bestFrom) {
      usage = `Perfecte bonen voor de komende ${bestUntil - days} dagen`;
    } else {
      usage = `Beste kwaliteit over ${bestFrom - days} dagen`;
    }
    return usage;
  };

  if (variant === 'a') {
    return (
      <Card className="beansCard">
        <Card.Body>
          <div className="metaInfo">
            { coffeeBrand && <div className="coffeeBrand">{coffeeBrand}</div> }
            <div className="coffeeName">{coffeeName}</div>
          </div>
          <DataField label="Gebrand">
            {roastDate
              ? formatDate(roastDate)
              : '(geen branddatum)'}
          </DataField>
          { roastDate && (
          <DataField label="Advies">
            {usageAdvice()}
          </DataField>
          )}
        </Card.Body>
        {imageUrl && (
          <Card.Img variant="bottom" src={imageUrl} />
        )}
      </Card>
    );
  }

  // variant: B
  return (
    <Card className="beansCard">
      <Card.Body>
        <div className="container">
          <div className="column">
            <div className="metaInfo">
              { coffeeBrand && <div className="coffeeBrand">{coffeeBrand}</div> }
              <div className="coffeeName">{coffeeName}</div>
            </div>
            <DataField label="Gebrand">
              {roastDate
                ? formatDate(roastDate)
                : '(geen branddatum)'}
            </DataField>
            { roastDate && (
            <DataField label="Advies">
              {usageAdvice()}
            </DataField>
            )}
          </div>
          {imageUrl && (
          <div className="column imageContainer">
            <img src={imageUrl} alt={coffeeName} />
          </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

BeansCard.defaultProps = {
  coffeeBrand: undefined,
  imageUrl: undefined,
  roastDate: undefined,
};

export default BeansCard;
