import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

interface DataFieldProps {
  label: string,
  children: string,
}

const DataField = ({ label, children, ...props }: DataFieldProps) => (
  <div className="data-field">
    <span className="label">{label}</span>
    <span className="value">
      {children}
    </span>
  </div>
);

DataField.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default DataField;
