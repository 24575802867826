import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import TimeAgo from 'react-timeago';

import Rating from '../Rating';

import './style.css';

interface CoffeeCardProps {
  coffeeName: string,
  coffeeBrand: string,
  username: string,
  imageUrl?: string,
  comment: string,
  rating: number,
  openDateTime: Date,
  averageRating: number,
  ratingCount: number,
  onClick?: Props.ClickHandler,
}

const CoffeeCard = (props: CoffeeCardProps) => {
  const {
    coffeeName, coffeeBrand, username, imageUrl, comment, rating, openDateTime, averageRating, ratingCount, onClick,
  } = props;

  const handleClick = () => {
    if (onClick !== undefined) {
      onClick();
    }
  };

  return (
    <Card className="coffeeCard" onClick={handleClick}>
      <div className="boxContainer">
        <div className="column-1 box">
          <div className="metaInfo">
            { coffeeBrand && <div className="coffeeBrand">{coffeeBrand}</div> }
            <div className="coffeeName">{coffeeName}</div>
          </div>
          <div className="userInfo">
            <span className="who">{username}</span>
            { comment && (
            <span className="comment">
              {comment}
            </span>
            )}
            { openDateTime && (
            <div className="when">
              <TimeAgo
                date={openDateTime}
                live={false}
              />
            </div>
            )}
          </div>
          <div className="rating">
            <Rating
              rating={rating}
              readOnly
            />
            { averageRating && (
            <span className="small">
              Gemiddeld
              &nbsp;
              {averageRating}
              &nbsp;
              (
              {ratingCount}
              &nbsp;
              beoordelingen)
            </span>
            )}
            { !averageRating && (
              <span className="small">&nbsp;</span>
            )}
          </div>
        </div>
        <div className="box">
          { imageUrl && (
          <div className="imageContainer">
            <img src={imageUrl} alt={coffeeName} />
          </div>
          )}
        </div>
      </div>
    </Card>
  );
};

CoffeeCard.propTypes = {
  coffeeBrand: PropTypes.string,
  coffeeName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  comment: PropTypes.string,
  openDateTime: PropTypes.instanceOf(Date).isRequired,
  rating: PropTypes.number,
  averageRating: PropTypes.number,
  ratingCount: PropTypes.number,
  onClick: PropTypes.func,
};

CoffeeCard.defaultProps = {
  coffeeBrand: undefined,
  rating: undefined,
  imageUrl: undefined,
  comment: undefined,
  averageRating: undefined,
  ratingCount: undefined,
  onClick: undefined,
};

export default CoffeeCard;
