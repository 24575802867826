import React from 'react';
import Rater from 'react-rater';
import PropTypes from 'prop-types';

import 'react-rater/lib/react-rater.css';
import './Rating.css';

const Rating = (props) => {
  const { rating, readOnly, onChangeRating } = props;
  return (
    <Rater
      rating={rating}
      interactive={!readOnly}
      onRate={onChangeRating}
    />
  );
};

Rating.propTypes = {
  rating: PropTypes.number,
  readOnly: PropTypes.bool,
  onChangeRating: PropTypes.func,
};

Rating.defaultProps = {
  rating: undefined,
  readOnly: false,
  onChangeRating: undefined,
};

export default Rating;
