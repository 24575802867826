import React, { useState } from 'react';
import {
  CloseButton, Form, Button, Spinner, Alert, Nav,
} from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useHistory } from 'react-router-dom';

import { useStore } from '../../store/Store';
import { addCoffee } from '../../store/FeedReducer';
import { useAuth } from '../../context/Auth.tsx';
import { fetchCoffeeSuggestions, submitNewCoffee } from '../../services/api.ts';
import { CheckInType, roastTypes } from '../../services/constants.ts';

import './style.css';

function useFetchSuggestions() {
  const [isLoadingSuggestions, setLoadingSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const { authToken } = useAuth();

  const fetchSuggestions = (query) => {
    setLoadingSuggestions(true);
    fetchCoffeeSuggestions(authToken, query)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        setLoadingSuggestions(false);
        setSuggestions(body);
      })
      .catch(() => {
        console.log('error!'); // TODO
      });
  };

  return {
    fetchSuggestions,
    isLoadingSuggestions,
    suggestions,
  };
}

function useSubmitData() {
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const [isSubmitError, setSubmitError] = useState(false);
  const { authToken } = useAuth();
  const [, dispatch] = useStore();

  const submitData = (data) => {
    setSubmitLoading(true);
    setSubmitSuccess(false);
    setSubmitError(false);
    submitNewCoffee(authToken, data)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        console.log('new coffee response', body);
        setSubmitLoading(false);
        setSubmitSuccess(true);
        dispatch(addCoffee(body));
      })
      .catch(() => {
        setSubmitLoading(false);
        setSubmitError(true);
        console.log('error!'); // TODO
      });
  };

  return {
    isSubmitLoading,
    isSubmitSuccess,
    isSubmitError,
    submitData,
  };
}

const RecipeForm = (props) => (
  <h3>Recipe Form</h3>
);

const CheckIn = () => {
  const {
    isSubmitLoading, isSubmitError, isSubmitSuccess, submitData,
  } = useSubmitData();
  const [coffeeName, setCoffeeName] = useState();
  const [coffeeInfo, setCoffeeInfo] = useState();
  const [roastType, setRoastType] = useState();
  const [coffeeComment, setCoffeeComment] = useState();
  const [roastDate, setRoastDate] = useState(undefined);
  const [validated, setValidated] = useState(false);
  const { fetchSuggestions, isLoadingSuggestions, suggestions } = useFetchSuggestions();

  const handleSearch = (query) => {
    setCoffeeName(query);
    fetchSuggestions(query);
  };

  const handleSelectSuggestion = (suggestion) => {
    console.log(`selected suggestion: ${JSON.stringify(suggestion)}`);
    setCoffeeInfo(suggestion);
    setCoffeeName(undefined);
  };

  const [activeTab, setActiveTab] = useState();
  const history = useHistory();

  const closeForm = () => {
    history.goBack();
  };

  function sendForm() {
    console.log(`coffeeName=${coffeeName} coffeeInfo=${JSON.stringify(coffeeInfo)}`);
    const data = {
      coffeeId: coffeeInfo !== undefined ? coffeeInfo.id : undefined,
      coffeeName: coffeeInfo === undefined ? coffeeName : undefined,
      // brewMethod: coffeeBrewMethod,
      // rating: coffeeRating,
      comment: coffeeComment,
      // imageData: coffeeImageData,
      checkInType: CheckInType.BEANS,
      roastType,
      roastDate,
    };
    console.log(`sending data: ${JSON.stringify(data)}`);
    submitData(data);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      console.log('submit');
      sendForm();
    }
    setValidated(true);
  };

  if (isSubmitSuccess) {
    return (
      <div>
        <Alert variant="success">Koffie aangemaakt!</Alert>
        <Button variant="primary" type="submit" onClick={closeForm}>
          Sluiten
        </Button>
      </div>
    );
  }

  let buttonContent;
  if (isSubmitLoading) {
    buttonContent = (
      <div className="buttonSpinner">
        <Spinner animation="border" size="sm" />
        <span>Bezig met versturen...</span>
      </div>
    );
  } else {
    buttonContent = <span>Voeg Toe</span>;
  }

  const handleTabSelect = (eventKey) => {
    console.log(`select tab: ${eventKey}`);
    setActiveTab(eventKey);
  };

  return (
    <div className="checkInContainer">
      <CloseButton onClick={closeForm} disabled={isSubmitLoading} />
      <h3>Wat wil je in-checken?</h3>
      <Nav variant="pills" defaultActiveKey="beans-tab" onSelect={handleTabSelect}>
        <Nav.Item>
          <Nav.Link eventKey="beans-tab">Bonen</Nav.Link>
        </Nav.Item>
        <Nav.Item hidden>
          <Nav.Link eventKey="recipe-tab">Recept</Nav.Link>
        </Nav.Item>
        <Nav.Item hidden>
          <Nav.Link eventKey="coffeebar-tab" disabled>
            Koffiebar
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {(activeTab === undefined || activeTab === 'beans-tab') && (
          <div>
            <div>
              Nieuwe koffie toevoegen aan je koffie-voorraad.
            </div>
            <Form.Group>
              <Form.Label>
                Wat is de
                &nbsp;
                <strong>naam</strong>
                <span className="required">*</span>
                &nbsp;?
              </Form.Label>
              <AsyncTypeahead
                id="coffeeNameInput"
                isLoading={isLoadingSuggestions}
                labelKey="name"
                placeholder="Type naam van de koffie..."
                minLength={3}
                onSearch={handleSearch}
                options={suggestions}
                onChange={(opt) => handleSelectSuggestion(opt[0])}
                disabled={isSubmitLoading}
                required
              />
            </Form.Group>
            <Form.Group controlId="roastType">
              <Form.Label>
                Wat is de
                &nbsp;
                <strong>branding</strong>
                <span className="required">*</span>
                &nbsp;?
              </Form.Label>
              <Form.Control
                as="select"
                disabled={isSubmitLoading}
                onChange={(e) => setRoastType(e.target.value)}
                value={roastType}
                required
              >
                <option key="0" value="">Selecteer branding</option>
                {
                  Object.entries(roastTypes).map(
                    ([code, title]) => <option key={code} value={code}>{title}</option>,
                  )
                }
              </Form.Control>
              {/* <Form.Control.Feedback type="invalid">
                Zijn het bonen of kies de juiste maling.
              </Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group>
              <Form.Label>Wat is de brand datum?</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => setRoastDate(e.target.value)}
                value={roastDate}
                disabled={isSubmitLoading}
                aria-describedby="roastDateHelpBlock"
              />
              <Form.Text id="roastDateHelpBlock" muted>
                Vul je de brand datum in van je koffie?
                Dan houden de app bij wanneer deze optimaal is om te gebruiken.
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>Notities</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                disabled={isSubmitLoading}
                onChange={(e) => setCoffeeComment(e.target.value)}
                value={coffeeComment}
                aria-describedby="notesHelpBlock"
              />
              <Form.Text id="notesHelpBlock" muted>
                Voeg hier je notities of aankoop locatie toe.
              </Form.Text>
            </Form.Group>
          </div>
        )}
        {activeTab === 'recipe-tab' && <RecipeForm />}
        { isSubmitError && <Alert variant="danger">Toevoegen mislukt. Probeer het later nog een keer.</Alert>}
        <Button variant="primary" type="submit" disabled={isSubmitLoading}>
          {buttonContent}
        </Button>
      </Form>
    </div>
  );
};

export default CheckIn;
