const adminBaseUrl = `${process.env.REACT_APP_API_URL}/admin`;

export const adminUnapprovedFeedAPI = `${adminBaseUrl}/feed/unapproved`;
export const adminPostApprovalAPI = (feedId: number) => `${adminBaseUrl}/feed/${feedId}`;
export const adminDeleteSpamAPI = (feedId: number) => `${adminBaseUrl}/feed/${feedId}`;

const buildAuthHeader = (authToken: string) => ({
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
});

export const adminFetchUnapprovedFeed = (authToken: string) => {
  console.log('fetch admin unapproved feed');
  const authHeader = buildAuthHeader(authToken);
  return fetch(adminUnapprovedFeedAPI, authHeader);
};

export const adminSubmitApproval = (authToken: string, feedId: number, data: any) => {
  console.log('submit approval');
  const authHeader = buildAuthHeader(authToken);
  const requestOptions = {
    ...authHeader,
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetch(adminPostApprovalAPI(feedId), requestOptions);
};

export const adminSubmitSpam = (authToken: string, feedId: number, data: any) => {
  console.log('submit spam');
  const authHeader = buildAuthHeader(authToken);
  const requestOptions = {
    ...authHeader,
    method: 'DELETE',
    body: JSON.stringify(data),
  };
  return fetch(adminDeleteSpamAPI(feedId), requestOptions);
};
