import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Form,
  Alert,
  Spinner,
  Image,
} from 'react-bootstrap';

import { useAuth } from '../../context/Auth.tsx';
import { submitUserRegistration } from '../../services/api.ts';
import IconAttribution from '../../components/icon-attribution/icon-attribution';

import logo from '../../images/coffee-break.png';
import './style.css';

const Signup = () => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [showTips, setShowTips] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [signupError, setSignupError] = useState('');

  const { setAuthToken } = useAuth();

  const submitSignup = () => {
    setLoading(true);
    const signupRequest = {
      email,
      username,
      password,
    };
    submitUserRegistration(signupRequest)
      .then(async (res) => {
        setLoading(false);
        if (!res.ok) {
          throw await res.json();
        }
        return res.json();
      })
      .then((body) => {
        console.log('signup success, now login and redirect to welcome screen!');
        const { token } = body;
        setAuthToken(token);
        setLoggedIn(true);
      })
      .catch((err) => {
        setError(true);
        setValidated(false);
        // reset error state.
        setSignupError('');
        setEmailError('');
        setUsernameError('');
        // get scoped errors.
        const { errors } = err;
        errors.forEach((code) => {
          switch (code) {
            case 'USERNAME_EXISTS':
              setUsernameError('Deze gebruikersnaam is al in gebruik.');
              break;
            case 'EMAIL_EXISTS':
              setEmailError('Dit e-mailadres is al in gebruik.');
              break;
            default:
              setSignupError(`Ongeldige invoer (code: ${code}).`);
          }
        });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const isValid = form.checkValidity();
    setValidated(true);
    if (isValid) {
      submitSignup();
    } else {
      setShowTips(true);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Form className="signupForm" noValidate validated={validated} onSubmit={handleSubmit}>
      <Image src={logo} fluid className="logo" />
      <div className="signupPromo">
        Wil je bijhouden welke koffies je drinkt?
        Zien wat andere koffie liefhebbers drinken en nieuwe koffie soorten ontdekken?
        Maak hieronder je account aan om toegang te krijgen.
      </div>
      {isError
      && (
      <Alert key="danger" variant="danger">
        <div>{signupError}</div>
        <div>{emailError}</div>
        <div>{usernameError}</div>
      </Alert>
      )}
      <fieldset disabled={isLoading}>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Control
            type="email"
            placeholder="Je e-mailadres"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            minLength={6}
            required
            aria-describedby="emailHelpBlock"
            isInvalid={emailError}
          />
          <Form.Text id="emailHelpBlock" muted hidden={!showTips}>
            Je e-mailadres wordt niet gedeeld met andere gebruikers.
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formUsername">
          <Form.Control
            placeholder="Je gebruikersnaam"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            pattern="^[a-zA-Z]{1}[a-zA-Z0-9]{5,19}$"
            required
            aria-describedby="usernameHelpBlock"
            isInvalid={usernameError}
            autoCorrect="off"
            autoCapitalize="none"
          />
          <Form.Text id="usernameHelpBlock" muted hidden={!showTips}>
            Een geldige gebruikersnaam is minimaal 6 karakters en maximaal 20 karakters lang.
            Je gebruikersnaam moet beginnen met een karakter en mag alleen uit karakters, en cijfers bestaan.
            Je gebruikersnaam is zichtbaar voor andere gebruikers.
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Control
            type="password"
            placeholder="Kies je wachtwoord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
            required
            aria-describedby="passwordHelpBlock"
          />
          <Form.Text id="passwordHelpBlock" muted hidden={!showTips}>
            Een geldig wachtwoord is tenminste 8 karakters lang zijn,
            en bestaan uit tenminsten één cijfer en één speciaal karakter.
          </Form.Text>
        </Form.Group>
        <div className="d-grid gap-2">
          <Button variant="primary" size="lg" type="submit">
            {isLoading
            && (
              <>
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                &nbsp;
              </>
            )}
            Registeren
          </Button>
        </div>
      </fieldset>
      <IconAttribution keyword="relax" author="dreamicons" />
    </Form>
  );
};

export default Signup;
