import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import Header from '../../components/header';
import Footer from '../../components/footer';
import CoffeeCard from '../../components/coffee-card/index.ts';
import BeansCard from '../../components/beans-card/index.ts';

import { useAuth, AuthContextType } from '../../context/Auth.tsx';
import { fetchFeed } from '../../services/api.ts';
import { CheckInType } from '../../services/constants.ts';

import './style.css';

function useFetchData() {
  const [isLoading, setLoading] = useState(false);
  const [isLoggedOut, setLoggedOut] = useState(false);
  const [data, setData] = useState([]);
  const { authToken } = useAuth() as AuthContextType;
  useEffect(() => {
    if (authToken) {
      setLoading(true);
      fetchFeed(authToken)
        .then((res) => {
          if (!res.ok) {
            if (res.status === 401) {
              console.log('token expired');
              setLoggedOut(true);
            } else {
              throw new Error(res.statusText);
            }
          }
          return res;
        })
        .then((res) => res.json())
        .then((body) => {
          setLoading(false);
          setData(body);
        })
        .catch(() => {
          console.log('error!'); // TODO
        });
    }
  }, [authToken]);
  return {
    isLoading,
    isLoggedOut,
    data,
  };
}

const FeedOverview = () => {
  const { isLoading, isLoggedOut, data } = useFetchData();
  const history = useHistory();

  const showDetail = (item: any) => {
    console.log(`show detail item: ${item.id}`);
    history.push(`/detail/${item.id}`);
  };

  if (isLoggedOut) {
    return <Redirect to="/logout" />;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header />
      <div className="feed-container">
        {data.map((item: any) => {
          if (item.checkInType === CheckInType.BEANS) {
            return (
              <div
                key={item.id}
                onClick={() => showDetail(item)}
                aria-hidden="true"
              >
                <BeansCard
                  coffeeBrand={item.brand.name}
                  coffeeName={item.name}
                  roastDate={item.roastDate ? new Date(item.roastDate) : undefined}
                  imageUrl={item.image || item.productImage}
                />
              </div>
            );
          }
          /* Render legacy coffee-card, not aware of check-in type */
          return (
            <CoffeeCard
              key={item.id}
              coffeeBrand={item.brand.name}
              coffeeName={item.name}
              username={item.user.username}
              imageUrl={item.image}
              comment={item.comment}
              openDateTime={new Date(item.dateAdd)}
              rating={item.rating}
              onClick={() => showDetail(item)}
            />
          );
        })}
      </div>
      <Footer />
    </div>
  );
};

export default FeedOverview;
