import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../context/Auth.tsx';

const Logout = () => {
  const { clearAuthResult } = useAuth();

  useEffect(() => {
    clearAuthResult();
  });

  return <Redirect to="/" />;
};

export default Logout;
