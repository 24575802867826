import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './context/Auth.tsx';

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authToken } = useAuth();

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props) => (authToken ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...props} />
      ) : (
        <Redirect
          // eslint-disable-next-line react/prop-types
          to={{ pathname: '/login', state: { referer: props.location } }}
        />
      ))}
    />
  );
};

export default PrivateRoute;
